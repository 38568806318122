<script>
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ProjectLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
  },
  mounted() {
    this.$store
      .dispatch("oauth", this.$route.params.token)
      .then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            icon: "CoffeeIcon",
            variant: "success",
            title: this.$i18n.t("Bem-vindo ") + response.data.users.fullName,
            text:
              this.$i18n.t("Você fez login com sucesso como ") +
              response.data.users.role +
              this.$i18n.t(". Agora você pode começar a explorar!"),
          },
        });
        this.$ability.update(response.data.users.roles);
        this.$router.push("/");
      })
      .catch((err) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            icon: "CoffeeIcon",
            variant: "danger",
            title: this.$i18n.t("Falha ao logar com a conta do Google"),
            text: err,
          },
        });
        this.$router.push("/");
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
